.pieChartContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.showAll {
  color: rgb(17, 105, 167);
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 10px;
  text-align: center;
}

.numberOfLines {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 60px;

  :global {
    .eds-expandable-text__trigger {
      padding-bottom: 0 !important;
    }

    .eds-expandable-text__content {
      padding-left: 0 !important;
    }
  }
}

.numberOfLines > button:focus {
  box-shadow: none;
}

.headerContainer {
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.header {
  margin: 0;
}

.showAllButton {
  justify-self: flex-end;
}