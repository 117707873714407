@import '~@entur/tokens/dist/styles.scss';

.sortIconStyle {
  cursor: pointer;
  font-weight: 700;
  height: 20px;
  align-self: flex-end;
}

.providerTitle {
  text-transform: uppercase;
  margin-left: 10px;
}

.headerContainer {
  display: flex;
  padding-right: 37px;
}

.endDate {
  margin-left: auto;
}

.startDate {
  margin-right: auto;
}

.validFromDate {
  margin-left: 35%;
}

.linesListContainer {
  height: calc(100vh - 120px);
  overflow-y: auto;
  overflow-x: hidden;
  margin: auto;
  width: 100%;
}

.linesValidityListContainer {
  margin: 15px 15px 50px;
  padding: 5px;
}