.numberOfLines {
  display: flex;
  justify-content: space-around;
}

.totalNumberOfLinesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.totalNumberOfLines {
  margin: 15px 0 0;
}

.numberOfLinesPerCategory {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 24px;
}

.numberOfLinesForCategory {
  margin: 0;
  line-height: 3rem;
}
