.linesStatisticsForProvider {
  display: flex;
  margin: 10px;
  flex-direction: column;

  .pieChartContainer > * {
    width: 280px
  }
}

.lineStatisticsForAllProviders {
  margin: 10px;

  .pieChartContainer > * {
    width: 235px
  }
}

.pieStatisticsForAllProviders {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.pieStatisticsForAllProviders > div {
  margin: 5px;
  padding: 10px;
}

.lineStatisticsCard {
  flex: 4;
}

.linesStatisticsContainer {
  display: flex;
}

.heading {
  text-align: center;
  margin: 25px;
}

.rightPanel {
  display: flex;
  flex-direction: column;
}

.showAllExportedLinesButton {
  padding-right: 35px;
  padding-left: 35px;
}

.showAllExportedLinesButton > span {
  color: #fff !important; // We need this because ninkasi has the global black color for span, which updates this button text color.
}